<template>
    <div class="d-flex flex-column vh-100">
        <div class="container py-5 my-0 my-sm-5 px-4 not-layout">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                    <div class="row mb-2">
                        <div class="col-12">
                            <div class="row align-items-center mb-0 pb-5 mb-sm-2">
                                <div class="col-4 text-left">
                                    <div class="logo-wrapper">
                                        <!-- <img src="@/assets/img/bau-logo-for-light.svg"/> -->
                                        <img :src="$store.getters['dashboard/getImage']('logo.png')" height="175" />
                                    </div>
                                </div>
                                <div class="col-4 text-center">
                                    <h4 class="mb-0">{{ $t('epayment') }}</h4>
                                </div>
                                <div class="col-4 text-right">
                                    <user-button :nameSurname="epaymentUser.name + ' ' + epaymentUser.surname" :isLogin="true" @epaymentLogout="logout"></user-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-tabs content-class="py-5 position-relative" v-model="tabIndex">
                        <div class="tabs-bottom-line"></div>
                        <b-tab @click="clearDebt()">
                            <template #title><i class="ri-price-tag-line top-plus-2 mr-2"></i>{{ $t('fees_and_installments') }}</template>

                            <!-- Loading -->
                            <div class="spinner text-center" v-if="debtsLoading">
                                <b-spinner label="Spinning" variant="primary"></b-spinner>
                            </div>
                            <div v-else>
                                <div class="text-center py-5 my-5 w-100" v-if="
                                !((debts.payment_plans && debts.payment_plans.length)
                                || (debts.payments && debts.payments.length)
                                || (debts.other_payments && debts.other_payments.length)
                                || (debts.certificate_plans && debts.certificate_plans.length)
                                || (debts.certificate_payments && debts.certificate_payments.length)
                                || (debts.external_payments && debts.external_payments.length))">
                                    <div class="mb-5">
                                        <i class="ri-calculator-line ri-5x text-muted"></i>
                                    </div>
                                    <h4 class="mb-4 text-black-50">{{ $t('you_dont_have_a_payment_plan') }}</h4>
                                </div>
                            </div>

                            <!-- Debts -->
                            <div class="row my-1 my-md-5" v-show="!debtsLoading">
                                <div class="col-12 col-md-10 col-lg-10 offset-md-1 offset-lg-1">
                                    <b-card no-body class="mb-3 border-0" v-for="(item1, key1) in debts.payment_plans" :key="'accordion1-'+key1">
                                        <b-card-header header-tag="header" class="px-0 py-1 m-0 border-0 bg-transparent" role="tab">
                                            <b-button block v-b-toggle="'accordion1-'+key1"
                                                      variant="secondary bg-gray-100 text-dark text-capitalize h-auto font-weight-medium line-height-normal py-2">
                                                <div class="d-block d-md-flex justify-content-between w-100">
                                                    <div class="text-left mb-1 mb-md-0 pr-3 d-flex align-items-center">
                                                        {{ getLocaleText(item1.program, 'name') }}
                                                        {{ item1.academic_year ? ' - ' + item1.academic_year : '' }} -
                                                        {{ getLocaleText(item1, 'product_name').toUpper() }}
                                                        {{item1.explanation?' ('+getLocaleText(item1, 'explanation').toUpper()+')':''}}
                                                    </div>
                                                    <div class="text-left font-weight-bold white-space-nowrap h6 mb-0">
                                                        {{ (item1.cash_price / ((item1.tax_rate+100)/100)) | formatToCurrency(item1.currency) }}
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion1-'+key1" accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="px-0 py-3">

                                                <!-- Peşin Ödeme  -->
                                                <b-card no-body class="mb-3 border-0" v-if="item1.allow_cash">
                                                    <b-card-header header-tag="header" class="p-0 m-0 border-0 bg-transparent" role="tab">
                                                        <b-button @click="tabSelected('cash')" block v-b-toggle.accordion-pesin variant="outline-primary text-capitalize font-weight-medium line-height-normal">
                                                            <div class="d-flex justify-content-between align-items-center w-100">
                                                                <div class="text-left d-flex align-items-center text-uppercase">
                                                                    <i class="ri-dual-sim-1-line mr-2 font-size-1"></i>
                                                                    <span class="top-plus-1">{{ $t('advance_payment').toUpper() }}</span>
                                                                </div>
                                                                <div class="text-left font-weight-bold white-space-nowrap h6 mb-0">
                                                                    {{ (item1.cash_price / ((item1.tax_rate+100)/100)) | formatToCurrency(item1.currency) }}
                                                                </div>
                                                            </div>
                                                        </b-button>
                                                    </b-card-header>
                                                    <b-collapse id="accordion-pesin" accordion="accordion-1" role="tabpanel">
                                                        <b-card-body class="px-0">
                                                            <b-card-text>
                                                                <b-table
                                                                    :empty-text="$t('there_are_no_records')"
                                                                    bordered
                                                                    striped
                                                                    responsive
                                                                    :items="modifyProgramStatusItems('advance',item1)"
                                                                    :fields="modifyProgramStatusFields('advance')"
                                                                    show-empty
                                                                    class="mb-3 table-dropdown no-scrollbar border rounded">
                                                                    <template #cell(total_price)="data">
                                                                        {{ data.value | formatToCurrency(item1.currency) }}
                                                                    </template>
                                                                    <template #cell(payments_sum_amount)="data">
                                                                        {{ data.value | formatToCurrency(item1.currency) }}
                                                                    </template>
                                                                    <template #cell(debt)="data">
                                                                        {{ data.value | formatToCurrency(item1.currency) }}
                                                                    </template>
                                                                </b-table>
                                                                <div class="alert alert-warning">
                                                                    Eğitim harcaması kampanyalarından faydalanmak için ödeme yapmak istediğiniz kredi kartınızın kampanyalarını kontrol etmeniz gerekmektedir.
                                                                </div>
                                                                <b-button type="button"
                                                                          variant="primary btn-block"
                                                                          @click="paymentSetVars('payment_plan', item1.uuid, 'advance')">
                                                                    {{ $t('pay') }}
                                                                </b-button>
                                                            </b-card-text>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>

                                                <!-- Taksitli Ödeme -->
                                                <b-card no-body class="mb-3 border-0" v-if="item1.allow_installment">
                                                    <b-card-header header-tag="header" class="p-0 m-0 border-0 bg-transparent" role="tab">
                                                        <b-button @click="tabSelected('with_installment')" block v-b-toggle.accordion-taksit variant="outline-primary text-capitalize font-weight-medium line-height-normal">
                                                            <div class="d-flex justify-content-between align-items-center w-100">
                                                                <div class="text-left d-flex align-items-center text-uppercase">
                                                                    <i class="ri-menu-line mr-2 font-size-1"></i>
                                                                    <span class="top-plus-1">{{ $t('installment_payment').toUpper() }}</span>
                                                                </div>
                                                                <div class="text-left font-weight-bold white-space-nowrap h6 mb-0">
                                                                    {{ item1.tax_free_price | formatToCurrency(item1.currency) }}
                                                                </div>
                                                            </div>
                                                        </b-button>
                                                    </b-card-header>
                                                    <b-collapse id="accordion-taksit" accordion="accordion-1" role="tabpanel">
                                                        <b-card-body class="px-0">
                                                            <b-card-text>
                                                                <template>
                                                                    <b-table
                                                                        :empty-text="$t('there_are_no_records')"
                                                                        bordered
                                                                        striped
                                                                        responsive
                                                                        :items="modifyProgramStatusItems('installment',item1)"
                                                                        :fields="modifyProgramStatusFields('installment')"
                                                                        show-empty
                                                                        class="mb-3 table-dropdown no-scrollbar border rounded">
                                                                        <template #cell(total_price)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                        <template #cell(payments_sum_amount)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                        <template #cell(debt)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                    </b-table>
                                                                </template>
                                                                <template>
                                                                    <b-table
                                                                        :empty-text="$t('there_are_no_records')"
                                                                        bordered
                                                                        striped
                                                                        responsive
                                                                        show-empty
                                                                        :fields="modifyPosFields( 'installment')"
                                                                        :items="modifyPosItems( 'installment',debts.virtual_pos, item1.currency)"
                                                                        class="mb-3 table-dropdown no-scrollbar border rounded"
                                                                    >
                                                                        <template #cell(card_type)="data">
                                                                            <img :src="getPosImage(data.value)" style="max-height:18px; max-width:70px">
                                                                        </template>
                                                                        <template #cell(installments)="data">
                                                                            {{ data.value.map(i => i +(data.item.extra_installment>0?'+'+data.item.extra_installment:'')+ ' ' + $t('installment')).join(", ") }}
                                                                        </template>
                                                                    </b-table>
                                                                    <div class="alert alert-warning">
                                                                        Eğitim harcaması kampanyalarından faydalanmak için ödeme yapmak istediğiniz kredi kartınızın kampanyalarını kontrol etmeniz gerekmektedir.
                                                                    </div>
                                                                    <b-button type="button"
                                                                              variant="primary btn-block"
                                                                              @click="paymentSetVars('payment_plan', item1.uuid, 'installment')">
                                                                        {{ $t('pay') }}
                                                                    </b-button>
                                                                </template>
                                                            </b-card-text>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>

                                                <b-card no-body class="mb-3 border-0" v-if="item1.allow_vinov">
                                                    <b-card-header header-tag="header" class="p-0 m-0 border-0 bg-transparent" role="tab">
                                                        <b-button @click="tabSelected('vinov')" block v-b-toggle.accordion-vinov variant="outline-primary text-capitalize font-weight-medium line-height-normal">
                                                            <div class="d-flex justify-content-between align-items-center w-100">
                                                                <div class="text-left d-flex align-items-center text-uppercase">
                                                                    <i class="ri-bank-line mr-2 font-size-1"></i>
                                                                    <span class="top-plus-1">{{ $t('pay_with_vakifbank_vinov').toUpper() }}</span>
                                                                </div>
                                                                <div class="text-left font-weight-bold white-space-nowrap h6 mb-0">
                                                                    {{ item1.tax_free_vinov_amount | formatToCurrency(item1.currency) }}
                                                                </div>
                                                            </div>
                                                        </b-button>
                                                    </b-card-header>
                                                    <b-collapse id="accordion-vinov" accordion="accordion-1" role="tabpanel">
                                                        <b-card-body class="px-0">
                                                            <b-card-text>
                                                                <template>
                                                                    <div class="alert alert-warning">{{debts.vinov_explanation}}</div>
                                                                </template>
                                                                <template>
                                                                    <b-table
                                                                        :empty-text="$t('there_are_no_records')"
                                                                        bordered
                                                                        striped
                                                                        responsive
                                                                        :items="modifyProgramStatusItems('vinov',item1)"
                                                                        :fields="modifyProgramStatusFields('installment')"
                                                                        show-empty
                                                                        class="mb-3 table-dropdown no-scrollbar border rounded">
                                                                        <template #cell(total_price)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                        <template #cell(payments_sum_amount)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                        <template #cell(debt)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                    </b-table>
                                                                </template>
                                                                <template>
                                                                    <div class="alert alert-info">* Vade tarihleri talimatı bugün vermeniz durumunda geçerli olan tarihlerdir</div>
                                                                    <b-table
                                                                        :empty-text="$t('there_are_no_records')"
                                                                        bordered
                                                                        striped
                                                                        responsive
                                                                        :items="item1.vinov_installments"
                                                                        :fields="vinovInstallmentFields"
                                                                        show-empty
                                                                        class="mb-3 table-dropdown no-scrollbar border rounded">
                                                                        <template #cell(amount)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                    </b-table>
                                                                </template>
                                                                <template>
                                                                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                                                                        <b-form-radio v-model="hasVinov" :aria-describedby="ariaDescribedby" name="vakifbank" value="1"><b>Vakıfbank Vinov hesabım var</b></b-form-radio>
                                                                        <b-form-radio v-model="hasVinov" :aria-describedby="ariaDescribedby" name="vakifbank" value="0"><b>Vakıfbank Vinov hesabım yok</b></b-form-radio>
                                                                    </b-form-group>
                                                                </template>
                                                                <template v-if="hasVinov=='1'">
                                                                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                                                                        <b-form-checkbox v-model="vinovForm.kvkk"><b @click="showModal('vinovKvkk');vinovForm.kvkk_read=true;">Kişisel verilerin işlenmesi hakkındaki aydınlatma metnini okudum ve kabul ediyorum</b></b-form-checkbox>
                                                                        <b-form-checkbox v-model="vinovForm.acik_riza"><b @click="showModal('vinovAcikRiza');vinovForm.acik_riza_read=true;">Kişisel verilerin aktarılmasına ilişkin rıza metnini okudum ve kabul ediyorum</b></b-form-checkbox>
                                                                    </b-form-group>
                                                                    <b-button type="button" :disabled="!vinovForm.kvkk || !vinovForm.acik_riza || !vinovForm.kvkk_read || !vinovForm.acik_riza_read"
                                                                              variant="primary btn-block"
                                                                              @click="showVinovForm(item1)">
                                                                        {{ $t('apply') }}
                                                                    </b-button>
                                                                </template>
                                                                <template v-if="hasVinov=='0'">
                                                                    <div class="alert alert-info">
                                                                        Vinov, Vakıfbank müşterilerine sunulan bir ödeme yöntemidir. Vakıfbank müşterisi olmak için
                                                                        <span v-for="(email,index) in debts.vinov_emails">
                                                                            <a :href="'mailto:'+email">{{email}}</a>
                                                                            <span v-if="index != debts.vinov_emails.length - 1">, </span>
                                                                        </span>
                                                                        adresleri ile iletişime geçebilirsiniz.
                                                                        <br>
                                                                        Vakıfbank müşterisi olup vinov hesabı olmayan müşterilerimizin dijital bankacılık uygulaması üzerinden Vinov hesap tanımlaması yapması gerekmektedir.
                                                                    </div>
                                                                </template>
                                                            </b-card-text>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>

                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>

                                    <!-- Payments -->
                                    <b-card no-body class="mb-3 border-0" v-for="(item2, key2) in debts.payments" :key="'accordion2-'+key2">
                                        <b-card-header header-tag="header" class="px-0 py-1 m-0 border-0 bg-transparent" role="tab">
                                            <b-button block v-b-toggle="'accordion2-'+key2" variant="secondary bg-gray-100 text-dark text-capitalize h-auto font-weight-medium line-height-normal py-2">
                                                <div class="d-block d-md-flex justify-content-between w-100">
                                                    <div class="text-left text-lg-center mb-1 mb-lg-0">
                                                        {{ getLocaleText(item2.program, 'name') }}
                                                        {{ item2.academic_year ? ' - ' + item2.academic_year : '' }}
                                                        {{ getLocaleText(item2, 'product_name').toUpper() }}
                                                    </div>
                                                    <div class="text-left text-lg-center font-weight-bold">
                                                        {{ item2.amount | formatToCurrency(item2.currency) }}
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion2-'+key2" accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="px-0 py-3">
                                                <p class="mb-1">{{ $t('advance_payment') }}</p>
                                                <div class="border rounded p-3 mb-3">
                                                    <b-row>
                                                        <b-col class="d-flex flex-column justify-content-center">
                                                            <h4 class="text-center mb-0">{{ item2.amount | formatToCurrency(item2.currency) }}</h4>
                                                        </b-col>
                                                        <b-col>
                                                            <b-button type="button" variant="primary btn-block"
                                                                      @click="paymentSetVars('payment', item2.uuid,'advance')">
                                                                {{ $t('pay') }}
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>

                                    <!-- Other Payments -->
                                    <b-card no-body class="mb-3 border-0" v-for="(item2, key2) in debts.other_payments" :key="'accordion3-'+key2">
                                        <b-card-header header-tag="header" class="px-0 py-1 m-0 border-0 bg-transparent" role="tab">
                                            <b-button block v-b-toggle="'accordion3-'+key2" variant="secondary bg-gray-100 text-dark text-capitalize h-auto font-weight-medium line-height-normal py-2">
                                                <div class="d-block d-md-flex justify-content-between w-100">
                                                    <div class="text-left text-lg-center mb-1 mb-lg-0">
                                                        {{ getLocaleText(item2.program, 'name') }}
                                                        {{ item2.academic_year ? ' - ' + item2.academic_year : '' }}
                                                        {{ getLocaleText(item2, 'product_name').toUpper() }}
                                                    </div>
                                                    <div class="text-left text-lg-center font-weight-bold">
                                                        {{ item2.amount | formatToCurrency(item2.currency) }}
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion3-'+key2" accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="px-0 py-3">
                                                <p class="mb-1">{{ $t('advance_payment') }}</p>
                                                <div class="border rounded p-3 mb-3">
                                                    <b-row>
                                                        <b-col class="d-flex flex-column justify-content-center">
                                                            <h4 class="text-center mb-0">{{ item2.amount | formatToCurrency(item2.currency) }}</h4>
                                                        </b-col>
                                                        <b-col>
                                                            <b-button type="button" variant="primary btn-block"
                                                                      @click="paymentSetVars('other_payment', item2.uuid,'advance')">
                                                                {{ $t('pay') }}
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>

                                    <!-- External Payments -->
                                    <b-card no-body class="mb-3 border-0" v-for="(item2, key2) in debts.external_payments" :key="'accordion4-'+key2">
                                        <b-card-header header-tag="header" class="px-0 py-1 m-0 border-0 bg-transparent" role="tab">
                                            <b-button block v-b-toggle="'accordion4-'+key2" variant="secondary bg-gray-100 text-dark text-capitalize h-auto font-weight-medium line-height-normal py-2">
                                                <div class="d-block d-md-flex justify-content-between w-100">
                                                    <div class="text-left text-lg-center mb-1 mb-lg-0">
                                                        {{ item2.explanation}}
                                                    </div>
                                                    <div class="text-left text-lg-center font-weight-bold">
                                                        {{ item2.price | formatToCurrency(item2.currency) }}
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion4-'+key2" accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="px-0 py-3">
                                                <p class="mb-1">{{ $t('advance_payment') }}</p>
                                                <div class="border rounded p-3 mb-3">
                                                    <b-row>
                                                        <b-col class="d-flex flex-column justify-content-center">
                                                            <h4 class="text-center mb-0">{{ item2.price | formatToCurrency(item2.currency) }}</h4>
                                                        </b-col>
                                                        <b-col>
                                                            <b-button type="button" variant="primary btn-block"
                                                                      @click="paymentSetVars('external_payment', item2.uuid,'advance')">
                                                                {{ $t('pay') }}
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>

                                    <b-card no-body class="mb-3 border-0" v-for="(item1, key1) in debts.certificate_plans" :key="'accordion5-'+key1">
                                        <b-card-header header-tag="header" class="px-0 py-1 m-0 border-0 bg-transparent" role="tab">
                                            <b-button block v-b-toggle="'accordion5-'+key1"
                                                      variant="secondary bg-gray-100 text-dark text-capitalize h-auto font-weight-medium line-height-normal py-2">
                                                <div class="d-block d-md-flex justify-content-between w-100">
                                                    <div class="text-left mb-1 mb-md-0 pr-3 d-flex align-items-center">
                                                        {{ getLocaleText(item1.certificate, 'name') }}
                                                    </div>
                                                    <div class="text-left font-weight-bold white-space-nowrap h6 mb-0">
                                                        {{ (item1.cash_price / ((item1.tax_rate+100)/100)) | formatToCurrency(item1.currency) }}
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion5-'+key1" accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="px-0 py-3">

                                                <!-- Peşin Ödeme  -->
                                                <b-card no-body class="mb-3 border-0" v-if="item1.allow_cash">
                                                    <b-card-header header-tag="header" class="p-0 m-0 border-0 bg-transparent" role="tab">
                                                        <b-button @click="tabSelected('cash')" block v-b-toggle.accordion-pesin variant="outline-primary text-capitalize font-weight-medium line-height-normal">
                                                            <div class="d-flex justify-content-between align-items-center w-100">
                                                                <div class="text-left d-flex align-items-center text-uppercase">
                                                                    <i class="ri-dual-sim-1-line mr-2 font-size-1"></i>
                                                                    <span class="top-plus-1">{{ $t('advance_payment').toUpper() }}</span>
                                                                </div>
                                                                <div class="text-left font-weight-bold white-space-nowrap h6 mb-0">
                                                                    {{ (item1.cash_price / ((item1.tax_rate+100)/100)) | formatToCurrency(item1.currency) }}
                                                                </div>
                                                            </div>
                                                        </b-button>
                                                    </b-card-header>
                                                    <b-collapse id="accordion-pesin" accordion="accordion-1" role="tabpanel">
                                                        <b-card-body class="px-0">
                                                            <b-card-text>
                                                                <b-table
                                                                    :empty-text="$t('there_are_no_records')"
                                                                    bordered
                                                                    striped
                                                                    responsive
                                                                    :items="modifyProgramStatusItems('advance',item1)"
                                                                    :fields="modifyProgramStatusFields('advance')"
                                                                    show-empty
                                                                    class="mb-3 table-dropdown no-scrollbar border rounded">
                                                                    <template #cell(total_price)="data">
                                                                        {{ data.value | formatToCurrency(item1.currency) }}
                                                                    </template>
                                                                    <template #cell(payments_sum_amount)="data">
                                                                        {{ data.value | formatToCurrency(item1.currency) }}
                                                                    </template>
                                                                    <template #cell(debt)="data">
                                                                        {{ data.value | formatToCurrency(item1.currency) }}
                                                                    </template>
                                                                </b-table>
                                                                <div class="alert alert-warning">
                                                                    Eğitim harcaması kampanyalarından faydalanmak için ödeme yapmak istediğiniz kredi kartınızın kampanyalarını kontrol etmeniz gerekmektedir.
                                                                </div>
                                                                <b-button type="button"
                                                                          variant="primary btn-block"
                                                                          @click="paymentSetVars('certificate_plan', item1.uuid, 'advance')">
                                                                    {{ $t('pay') }}
                                                                </b-button>
                                                            </b-card-text>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>

                                                <!-- Taksitli Ödeme -->
                                                <b-card no-body class="mb-3 border-0" v-if="item1.allow_installment">
                                                    <b-card-header header-tag="header" class="p-0 m-0 border-0 bg-transparent" role="tab">
                                                        <b-button @click="tabSelected('with_installment')" block v-b-toggle.accordion-taksit variant="outline-primary text-capitalize font-weight-medium line-height-normal">
                                                            <div class="d-flex justify-content-between align-items-center w-100">
                                                                <div class="text-left d-flex align-items-center text-uppercase">
                                                                    <i class="ri-menu-line mr-2 font-size-1"></i>
                                                                    <span class="top-plus-1">{{ $t('installment_payment').toUpper() }}</span>
                                                                </div>
                                                                <div class="text-left font-weight-bold white-space-nowrap h6 mb-0">
                                                                    {{ item1.tax_free_price | formatToCurrency(item1.currency) }}
                                                                </div>
                                                            </div>
                                                        </b-button>
                                                    </b-card-header>
                                                    <b-collapse id="accordion-taksit" accordion="accordion-1" role="tabpanel">
                                                        <b-card-body class="px-0">
                                                            <b-card-text>
                                                                <template>
                                                                    <b-table
                                                                        :empty-text="$t('there_are_no_records')"
                                                                        bordered
                                                                        striped
                                                                        responsive
                                                                        :items="modifyProgramStatusItems('installment',item1)"
                                                                        :fields="modifyProgramStatusFields('installment')"
                                                                        show-empty
                                                                        class="mb-3 table-dropdown no-scrollbar border rounded">
                                                                        <template #cell(total_price)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                        <template #cell(payments_sum_amount)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                        <template #cell(debt)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                    </b-table>
                                                                </template>
                                                                <template>
                                                                    <b-table
                                                                        :empty-text="$t('there_are_no_records')"
                                                                        bordered
                                                                        striped
                                                                        responsive
                                                                        show-empty
                                                                        :fields="modifyPosFields( 'installment')"
                                                                        :items="modifyPosItems( 'installment',debts.virtual_pos, item1.currency)"
                                                                        class="mb-3 table-dropdown no-scrollbar border rounded"
                                                                    >
                                                                        <template #cell(card_type)="data">
                                                                            <img :src="getPosImage(data.value)" style="max-height:18px; max-width:70px">
                                                                        </template>
                                                                        <template #cell(installments)="data">
                                                                            {{ data.value.map(i => i +(data.item.extra_installment>0?'+'+data.item.extra_installment:'')+ ' ' + $t('installment')).join(", ") }}
                                                                        </template>
                                                                    </b-table>
                                                                    <div class="alert alert-warning">
                                                                        Eğitim harcaması kampanyalarından faydalanmak için ödeme yapmak istediğiniz kredi kartınızın kampanyalarını kontrol etmeniz gerekmektedir.
                                                                    </div>
                                                                    <b-button type="button"
                                                                              variant="primary btn-block"
                                                                              @click="paymentSetVars('certificate_plan', item1.uuid, 'installment')">
                                                                        {{ $t('pay') }}
                                                                    </b-button>
                                                                </template>
                                                            </b-card-text>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>

                                                <b-card no-body class="mb-3 border-0" v-if="item1.allow_vinov">
                                                    <b-card-header header-tag="header" class="p-0 m-0 border-0 bg-transparent" role="tab">
                                                        <b-button @click="tabSelected('vinov')" block v-b-toggle.accordion-vinov variant="outline-primary text-capitalize font-weight-medium line-height-normal">
                                                            <div class="d-flex justify-content-between align-items-center w-100">
                                                                <div class="text-left d-flex align-items-center text-uppercase">
                                                                    <i class="ri-bank-line mr-2 font-size-1"></i>
                                                                    <span class="top-plus-1">{{ $t('pay_with_vakifbank_vinov').toUpper() }}</span>
                                                                </div>
                                                                <div class="text-left font-weight-bold white-space-nowrap h6 mb-0">
                                                                    {{ item1.tax_free_price | formatToCurrency(item1.currency) }}
                                                                </div>
                                                            </div>
                                                        </b-button>
                                                    </b-card-header>
                                                    <b-collapse id="accordion-vinov" accordion="accordion-1" role="tabpanel">
                                                        <b-card-body class="px-0">
                                                            <b-card-text>
                                                                <template>
                                                                    <div class="alert alert-warning">{{debts.vinov_explanation}}</div>
                                                                </template>
                                                                <template>
                                                                    <b-table
                                                                        :empty-text="$t('there_are_no_records')"
                                                                        bordered
                                                                        striped
                                                                        responsive
                                                                        :items="modifyProgramStatusItems('vinov',item1)"
                                                                        :fields="modifyProgramStatusFields('installment')"
                                                                        show-empty
                                                                        class="mb-3 table-dropdown no-scrollbar border rounded">
                                                                        <template #cell(total_price)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                        <template #cell(payments_sum_amount)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                        <template #cell(debt)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                    </b-table>
                                                                </template>
                                                                <template>
                                                                    <div class="alert alert-info">* Vade tarihleri talimatı bugün vermeniz durumunda geçerli olan tarihlerdir</div>
                                                                    <b-table
                                                                        :empty-text="$t('there_are_no_records')"
                                                                        bordered
                                                                        striped
                                                                        responsive
                                                                        :items="item1.vinov_installments"
                                                                        :fields="vinovInstallmentFields"
                                                                        show-empty
                                                                        class="mb-3 table-dropdown no-scrollbar border rounded">
                                                                        <template #cell(amount)="data">
                                                                            {{ data.value | formatToCurrency(item1.currency) }}
                                                                        </template>
                                                                    </b-table>
                                                                </template>
                                                                <template>
                                                                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                                                                        <b-form-radio v-model="hasVinov" :aria-describedby="ariaDescribedby" name="vakifbank" value="1"><b>Vakıfbank Vinov hesabım var</b></b-form-radio>
                                                                        <b-form-radio v-model="hasVinov" :aria-describedby="ariaDescribedby" name="vakifbank" value="0"><b>Vakıfbank Vinov hesabım yok</b></b-form-radio>
                                                                    </b-form-group>
                                                                </template>
                                                                <template v-if="hasVinov=='1'">
                                                                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                                                                        <b-form-checkbox v-model="vinovForm.kvkk"><b @click="showModal('vinovKvkk');vinovForm.kvkk_read=true;">Kişisel verilerin işlenmesi hakkındaki aydınlatma metnini okudum ve kabul ediyorum</b></b-form-checkbox>
                                                                        <b-form-checkbox v-model="vinovForm.acik_riza"><b @click="showModal('vinovAcikRiza');vinovForm.acik_riza_read=true;">Kişisel verilerin aktarılmasına ilişkin rıza metnini okudum ve kabul ediyorum</b></b-form-checkbox>
                                                                    </b-form-group>
                                                                    <b-button type="button" :disabled="!vinovForm.kvkk || !vinovForm.acik_riza || !vinovForm.kvkk_read || !vinovForm.acik_riza_read"
                                                                              variant="primary btn-block"
                                                                              @click="showCertificateVinovForm(item1)">
                                                                        {{ $t('apply') }}
                                                                    </b-button>
                                                                </template>
                                                                <template v-if="hasVinov=='0'">
                                                                    <div class="alert alert-info">
                                                                        Vinov, Vakıfbank müşterilerine sunulan bir ödeme yöntemidir. Vakıfbank müşterisi olmak için
                                                                        <span v-for="(email,index) in debts.vinov_emails">
                                                                            <a :href="'mailto:'+email">{{email}}</a>
                                                                            <span v-if="index != debts.vinov_emails.length - 1">, </span>
                                                                        </span>
                                                                        adresleri ile iletişime geçebilirsiniz.
                                                                        <br>
                                                                        Vakıfbank müşterisi olup vinov hesabı olmayan müşterilerimizin dijital bankacılık uygulaması üzerinden Vinov hesap tanımlaması yapması gerekmektedir.
                                                                    </div>
                                                                </template>
                                                            </b-card-text>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>

                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>

                                    <b-card no-body class="mb-3 border-0" v-for="(item2, key2) in debts.certificate_payments" :key="'accordion6-'+key2">
                                        <b-card-header header-tag="header" class="px-0 py-1 m-0 border-0 bg-transparent" role="tab">
                                            <b-button block v-b-toggle="'accordion6-'+key2" variant="secondary bg-gray-100 text-dark text-capitalize h-auto font-weight-medium line-height-normal py-2">
                                                <div class="d-block d-md-flex justify-content-between w-100">
                                                    <div class="text-left text-lg-center mb-1 mb-lg-0">
                                                        {{ getLocaleText(item2.certificate, 'name') }}
                                                    </div>
                                                    <div class="text-left text-lg-center font-weight-bold">
                                                        {{ item2.amount | formatToCurrency(item2.currency) }}
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion6-'+key2" accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="px-0 py-3">
                                                <p class="mb-1">{{ $t('advance_payment') }}</p>
                                                <div class="border rounded p-3 mb-3">
                                                    <b-row>
                                                        <b-col class="d-flex flex-column justify-content-center">
                                                            <h4 class="text-center mb-0">{{ item2.amount | formatToCurrency(item2.currency) }}</h4>
                                                        </b-col>
                                                        <b-col>
                                                            <b-button type="button" variant="primary btn-block"
                                                                      @click="paymentSetVars('certificate_payment', item2.uuid,'advance')">
                                                                {{ $t('pay') }}
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>

                                </div>
                            </div>
                        </b-tab>

                        <b-tab :disabled="paymentDebtId ? false:true">
                            <template #title><i class="ri-bank-card-line top-plus-2 mr-2"></i>{{ $t('pay') }}</template>
                            <div class="row my-1 my-md-5">

                                <div class="col-12">
                                    <div v-if="paymentFormMaxAmount===0 && paymentFormSumAmount>0">
                                        <div class="text-center py-5 my-5 fadeIn d-block h-auto show">
                                            <div class="mb-5">
                                                <i class="ri-checkbox-circle-line ri-6x text-success"></i>
                                            </div>
                                            <h4>{{ $t('your_payment_transaction_has_been_successfully_completed') }}</h4>
                                        </div>
                                    </div>
                                    <ValidationObserver ref="payForm">
                                        <div class="row" v-if="paymentFormMaxAmount>0">
                                            <div class="col-12">
                                                <div class="fadeIn show d-block mb-4">
                                                    <h5 class="mb-0">
                                                        <span v-if="paymentDebt.program">
                                                            {{ getLocaleText(paymentDebt.program, 'name').toUpper() }}
                                                        </span>
                                                        <span v-if="paymentDebt.academic_year">
                                                            {{ paymentDebt.academic_year ? ' - ' + paymentDebt.academic_year : '' }}
                                                        </span>
                                                        <span v-if="paymentDebt.product_name">
                                                            {{ ' - '+getLocaleText(paymentDebt, 'product_name').toUpper() }}
                                                            {{ paymentDebt.explanation ? '('+getLocaleText(paymentDebt, 'explanation')+')':'' }}
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <div class="row">
                                                    <div class="col-6 col-md-12" v-if="paymentFormPrice!=null && paymentDebt.currency">
                                                        <b-form-group :label="$t('total_amount')" >
                                                            <div><b>{{ paymentFormPrice | formatToCurrency(paymentDebt.currency) }}</b></div>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-6 col-md-12" v-if="paymentFormSumAmount!=null && paymentDebt.currency">
                                                        <b-form-group :label="$t('amount_paid')" >
                                                            <div><b>{{ paymentFormSumAmount | formatToCurrency(paymentDebt.currency) }}</b></div>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-12">
                                                        <ValidationProvider name="payment_total" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('payment_total')">
                                                                <b-form-input type="number" :disabled="!paymentDebt.allow_partial"
                                                                              v-model.sync="paymentFormAmount"
                                                                              min="0.1"
                                                                              :max="Math.ceil(parseFloat(paymentFormMaxAmount))"
                                                                              @focusout="paymentFormAmountCalculate"
                                                                              v-check-min-max-value
                                                                              :state="errors[0] ? false : (valid ? true : null)">
                                                                </b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-6 col-md-12" v-if="paymentDebt.currency">
                                                        <b-form-group :label="$t('remaining_amount')" >
                                                            <div><b>{{ paymentFormRemainingAmount | formatToCurrency(paymentDebt.currency) }}</b></div>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-8">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <ValidationProvider name="card_number" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('card_number')" class="position-relative">
                                                                <span class="bank-input-logo" v-if="paymentFormCreditCardLogo">
                                                                    <img :src="getPosImage(paymentFormCreditCardLogo)" style="max-height: 18px; max-width: 70px;">
                                                                </span>
                                                                <b-form-input type="text"
                                                                              v-model="paymentFormCreditCardNumber"
                                                                              :state="errors[0] ? false : (valid ? true : null)" v-mask="'#### #### #### ####'"
                                                                              autocomplete="off"
                                                                >
                                                                </b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12">
                                                        <ValidationProvider name="card_owner" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('card_owner')">
                                                                <b-form-input type="text"
                                                                              v-model="paymentFormCardHolderName"
                                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                                              autocomplate="off"
                                                                              v-uppercase
                                                                >
                                                                </b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-4">
                                                        <ValidationProvider name="expiry_month" rules="required" v-slot="{valid, errors}">
                                                            <fieldset class="form-group">
                                                                <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">{{ $t('month') }}</legend>
                                                                <multiselect
                                                                    :placeholder="$t('select')"
                                                                    v-model="paymentFormExpiryMonth"
                                                                    :options="paymentFormMonthOptions"
                                                                    :select-label="''"
                                                                    :selected-label="''"
                                                                    :deselect-label="''"
                                                                    :searchable="false"
                                                                    :allow-empty="true"
                                                                    :class="errors[0] ? 'is-invalid' : (valid ? 'is-valid' : null)"
                                                                >
                                                                </multiselect>
                                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                            </fieldset>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-4">
                                                        <ValidationProvider name="expiry_year" rules="required" v-slot="{valid, errors}">
                                                            <fieldset class="form-group">
                                                                <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">{{ $t('year') }}</legend>
                                                                <multiselect
                                                                    :placeholder="$t('select')"
                                                                    v-model="paymentFormExpiryYear"
                                                                    :options="paymentFormYearOptions"
                                                                    :select-label="''"
                                                                    :selected-label="''"
                                                                    :deselect-label="''"
                                                                    :searchable="false"
                                                                    :class="errors[0] ? 'is-invalid' : (valid ? 'is-valid' : null)"
                                                                >
                                                                </multiselect>
                                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                            </fieldset>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-4">
                                                        <ValidationProvider name="cvc" rules="required" v-slot="{valid, errors}">
                                                            <b-form-group label="CVC">
                                                                <b-form-input type="text"
                                                                              v-model="paymentFormCvv"
                                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                                              v-mask="'###'">
                                                                </b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12" v-if="paymentType=='installment'">
                                                        <ValidationProvider name="installment" rules="required" v-slot="{valid, errors}">
                                                            <fieldset class="form-group">
                                                                <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">{{ $t('installment_options') }}</legend>
                                                                <multiselect
                                                                    :placeholder="$t('select')"
                                                                    v-model="paymentFormInstallment"
                                                                    :options="paymentFormInstallmentOptions"
                                                                    :select-label="''"
                                                                    :selected-label="''"
                                                                    :deselect-label="''"
                                                                    label="name"
                                                                    :custom-label="paymentFormInstallmentCustomLabel"
                                                                    :disabled="paymentFormInstallmentDisabled"
                                                                    :class="errors[0] ? 'is-invalid' : (valid ? 'is-valid' : null)"
                                                                >
                                                                </multiselect>
                                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                            </fieldset>
                                                        </ValidationProvider>
                                                    </div>

                                                    <div class="col-12">
                                                        <ValidationProvider name="installment_options" rules="required" v-slot="{valid, errors}">
                                                            <fieldset class="form-group">
                                                                <b-form-checkbox v-model="paymentFormCheck" value="true" unchecked-value="">
                                                                    <div>
                                                                        <a href="#" v-b-modal.CommonModal @click="showModal('onBilgilendirme')">
                                                                            Ön Bilgilendirme Formu
                                                                        </a>
                                                                        'nu okudum onaylıyorum.
                                                                    </div>
                                                                    <div class="invalid-feedback d-block" v-if="errors[0]">
                                                                        Bu alan zorunludur
                                                                    </div>
                                                                </b-form-checkbox>
                                                            </fieldset>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12">
                                                        <b-button type="button" variant="primary btn-block" @click="pay">
                                                            <div class="d-flex justify-content-between w-100">
                                                                <div>{{ $t('pay').toUpper() }}</div>
                                                                <div v-if="paymentFormShowAmount && paymentDebt.currency">
                                                                    {{ paymentFormShowAmount | formatToCurrency(paymentDebt.currency) }}
                                                                </div>
                                                            </div>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </b-tab>

                        <b-tab v-if="debts && debts.done_payments">
                            <template #title><i class="ri-file-download-line top-plus-2 mr-2"></i>{{ $t('receipts') }}</template>
                            <div class="row my-1 my-md-5">

                                <div class="col-12">
                                    <table class="table table-bordered">
                                        <tr>
                                            <th>{{$t('date')}}</th>
                                            <th>{{$t('amount')}}</th>
                                            <th class="w-40"></th>
                                        </tr>
                                        <tr v-for="payment in debts.done_payments">
                                            <td>{{payment.date}}</td>
                                            <td>{{payment.amount}} {{payment.currency}}</td>
                                            <td><b-button @click="downloadReceipt(payment.id)" variant="outline-success" size="xs">{{$t('download')}}</b-button></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2" v-if="!selectedTab">
                    <div class="alert alert-warning">
                        Eğitim harcaması kampanyalarından faydalanmak için ödeme yapmak istediğiniz kredi kartınızın kampanyalarını kontrol etmeniz gerekmektedir.
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2" v-if="!selectedTab">
                    <div class="alert alert-info">
                        {{$t('tax_explanation')}}
                    </div>
                </div>

            </div>
        </div>

        <CommonModal ref="threeDPayModal" @bHideModalHeaderClose="closedThreeDPayModal($event)" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                {{ $t('pay') }}
            </template>
            <template v-slot:CommonModalContent>
                <iframe sandbox="allow-same-origin allow-top-navigation allow-forms allow-scripts" :srcdoc="paymentResult" width="100%" height="550px" class="border-0"></iframe>
            </template>
        </CommonModal>

        <CommonModal ref="paymentModal" :size="commonModalModeSize" :onHideOnlyX="true" >
            <template v-slot:CommonModalTitle>
                <div v-if="commonModalMode == 'onBilgilendirme'">{{ $t('preliminary_information_form') }}</div>
                <div v-if="commonModalMode == 'mesafeliSatis'">{{ $t('distance_sales_agreement') }}</div>
                <div v-if="commonModalMode == 'vinovKvkk'">{{ $t('vinov_kvkk') }}</div>
                <div v-if="commonModalMode == 'vinovAcikRiza'">{{ $t('vinov_acik_riza') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div v-if="commonModalMode == 'onBilgilendirme'">
                    <div v-if="$i18n.locale=='en'">
                        <h5>ONLINE PAYMENT GENERAL TERMS AND CONDITIONS</h5>

                        <p>1. I am using the “online payment” system on BAHÇEŞEHİR UNIVERSITY’s website of my own volition.</p>
                        <p>2. I will not share the password of the debit or credit card that I will use on the “online payment” system with anyone; under no circumstances will I allow third parties access to my account; I acknowledge that so long as the correct password is used, as far as BAHÇEŞEHİR UNIVERSITY is concerned, the transactions were made by me and that no other measures will be taken to verify their validity; I will take any and all precautions to ensure that my password and other verification codes will not be discovered by third parties, especially those who may have ill intent; and I accept all responsibility as far these matters are concerned.</p>
                        <p>3. I take full responsibility for any erroneous payments that may be made as a result of my negligence or mistake; that BAHÇEŞEHİR UNIVERSITY will bear no responsibility and I will make no claims or demands under any assumed name.</p>
                        <p>4. I am fully aware that the online payment system may only be used to make full payments on amounts owing; that the system will not accept partial or down payments.</p>
                        <p>5. I am fully aware that the system may only be used to make full payments on current amounts owing; that it may not be used to make any late, future or default payments; that under no circumstances will any payment made online be considered a payment toward a late, future or default payment.   </p>
                        <p>6. I have been made fully aware and acknowledge that I may benefit from discounted tuition offers made by BAHÇEŞEHİR UNIVERSITY during certain registration periods and announced via various outlets; outside of these periods, I may not benefit from any discount when registering with the “online payment” system; that having benefitted from discounted tuition in the past does not guarantee or contractually oblige BAHÇEŞEHİR UNIVERSITY to make any such offers in the future; that BAHÇEŞEHİR UNIVERSITY exclusively reserves the right to withdraw any offer at any time, to change the amount of the discount, and any and all rights regarding discounted tuition offers. </p>
                        <p>7. I acknowledge that BAHÇEŞEHİR UNIVERSITY will not share my personal account details with any third parties. </p>
                        <p>8. I acknowledge that any breach of contract on my part may result in BAHÇEŞEHİR UNIVERSITY barring my access to the “online payment” system and I may be held accountable for any damages or losses incurred by the university because of my actions.  </p>
                        <p>9. I acknowledge that BAHÇEŞEHİR UNIVERSITY is not responsible for any damages or losses, directly, indirectly, incidentally, or intentionally resulting from operational errors, loss of communications, negligence, power outages, break downs, maintenance works, etc. that may cause the suspension of the system, delays, computer viruses, cable or system failures.  </p>
                        <p>10. I acknowledge that any information, material, or content on the “online payment” pages of the website may be suspended, withdrawn, or changed without notice; that the terms and conditions may be revised without notice during updates; and I agree to keep up to date with all developments and consent to all potential changes to maintain my access to the system.   </p>
                        <p>11. I hereby declare that I will comply with all written terms and conditions outlined in this document, any relevant laws and legal provisions, and accept in advance any and all decisions made by BAHÇEŞEHİR UNIVERSITY.</p>
                        <p>I have read, understood, and agree to these terms and conditions.</p>
                    </div>
                    <div v-else>
                        <h5>ONLİNE ÖDEME GENEL ŞARTLAR VE TAAHHÜTNAME</h5>

                        <p>1. BAHÇEŞEHİR ÜNİVERSİTESİ’ne ait ‘’online ödeme’’ web sitesini kendi rızamla kullandığımı,</p>
                        <p>2. “Online ödeme” sisteminde kullandığım Bankamatik kartı/ kredi kartının şifrelerini gizli tutacağımı, her ne suretle olursa olsun 3. Kişilerin kulanımına izin vermeyeceğimi, işlemler doğru şifre ile yapıldığı müddetçe BAHÇEŞEHİR ÜNİVERSİTESİ tarafından şahsımca yapılmış olarak kabul edileceğini ve işlemin gerçekliğine dair ayrıca bir kontrol yapılmayacağını, şifrelerin kaybolmaması ver kötü niyetli kişilerin eline geçmemesi için gerekli her türlü tedbiri alacağımı, bu hususlarla ilgili her türlü sorumluluğun tarafıma ait olduğunu,</p>
                        <p>3. Kendi kusur ve ihmalim sebebiyle hatalı bir ödeme işlemi yapmam halinde buna ilişkin her türlü zarardan şahsen sorumlu olacağımı, BAHÇEŞEHİR ÜNİVERSİTESİ’nin hiçbir sorumluluğunun doğmayacağını ve BAHÇEŞEHİR ÜNİVERSİTESİ’nden herhangi bir nam veya ad altında talepte bulunmayacağımı,</p>
                        <p>4. Online ödeme sisteminin muaccel borç bakiyesinin tümünün ödenmesi için kullanılabileceğini, muaccel borç bakiyesi için kısmı ödeme yapılamayacağını, online ödeme sisteminin kapora ödemesi veya kısmi ödemeyi kabul etmeyeceğini bildiğimi,</p>
                        <p>5. Online ödeme sistemi ile yapılan ödemelerin sadece güncel borçlar için kullanılabileceğini, gecikmiş/temerrüde düşmüş ve/veya henüz muaccel olmamış borçlar için kullanılamayacağını,online ödeme sistemi kullanılarak yapılan ödemelerin hiçbir surette gecikmiş/temerrüde düşmüş veya henüz vadesi gelmemiş borçlara mahsuben yapılmış sayılmayacağını bildiğimi,</p>
                        <p>6. BAHÇEŞEHİR ÜNİVERSİTESİ’nin belirlemiş olduğu ve çeşitli bildirim vasıtalarıyla duyurduğu dönemlerde indirimli kayıt imkanını kullanabileceğimi, belirtilmiş olan dönemlerin dışında online ödeme sistemi ile kayıt yaptırdığımda  herhangi bir indirimli kayıt imkanından faydalanamayacağımı,indirimli kayıt imkanından bir kez faydalanmış olmanın ileriki dönemlerde de BAHÇEŞEHİR ÜNİVERSİTESİ’nin indirimli kayıt imkanı sunacağına dair bir garanti /taahhüt anlamına gelmeyeceğini, BAHÇEŞEHİR ÜNİVERSİTESİ’nin sağlamış olduğu indirimli kayıt imkanını  bütünüyle kaldırabileceğini, indirimli kayıt imkanında belirlediği indirim oranlarını değiştirebileceğini, bu husus ile ilgili tüm tasarruf/takdir hakkının münhasır olarak olarak BAHÇEŞEHİR ÜNİVERSİTESİ’nde olduğunu bildiğimi ve bununla ilgili olarak bilgilendirildiğimi,,</p>
                        <p>7. BAHÇEŞEHİR ÜNİVERSİTESİ’nin kişiye özel hesap bilgilerini 3. kişilerle paylaşmayacağını,</p>
                        <p>8. Sözleşmeye aykırı kullanımım halinde BAHÇEŞEHİR ÜNİVERSİTESİ’nin “online ödeme” sistemine erişimimi engelleyebileceğini ve bu sözleşmeye aykırı kullanım nedeniyle BAHÇEŞEHİR ÜNİVERSİTESİ’nin uğrayacağı zarar ve kayıplardan sorumlu olacağımı,</p>
                        <p>9. İşlemlerin gerçekleşmesi sırasında operasyonda ve iletimde başarısızlık/performans kaybı/hata/kasıtsız ihmal /kesinti/bozulma/gecikmelerden, bakım çalışması ve sair sebepler nedeniyle sistemin askıya alınmasından,işlem emrinin verilmesi zamanı ile gerçekleşmesi zamanı arasındaki farklılıklardan, bilgisayar virüsü , kablo veya sistem hatası dolayısıyla ortaya çıkabilecek doğrudan ya da dolaylı özel veya tesadüfi netice itibariyle meydana gelen her türlü zarar/kayıplardan BAHÇEŞEHİR ÜNİVERSİTESİ’nin sorumlu olmayacağını,</p>
                        <p>10. Online ödeme sitesindeki sayfalarda  yer alan her türlü bilgi, materyal ve içeriğin herhangi bir ihbarda bulunulmadan askıya alınabileceğini, geri çekilebileceğini  veya değiştirilebileceğini, şartlardaki değişikliklerin herhangi bir ihbarda bulunulmadan bu sayfanın güncellenmesi ile gerçekleştirileceğini, şartları düzenli olarak gözden geçireceğimi ve siteye erişimimi devam ettirmekle bu şartlarda yapılabilecek herhangi bir değişiklikte mutabık olduğumu,</p>
                        <p>11. İşbu taahhütnamede belirtilmiş olan yazılı koşullara, ilgili kanun ve yönetmelik hükümlerine, bu konuda BAHÇEŞEHİR ÜNİVERSİTESİ’nce alınacak her türlü karara, uymayı peşinen, beyan, kabul ve taahhüt ederim.</p>
                        <p>Okudum, anladım kabul ediyorum.</p>
                    </div>
                </div>
                <div v-if="commonModalMode == 'vinovKvkk'">
                    <h5>A. Veri Sorumlusu</h5>
                    <p>
                        Bahçeşehir Üniversitesi (“BAU” veya “Üniversitemiz”) olarak, kişisel verilerinizin korunmasına önem veriyoruz. Bu aydınlatma metni ile veri sorumlusu olarak kişisel verilerinizi nasıl işlediğimiz hakkında sizi bilgilendirmeyi amaçlıyoruz.
                    </p>

                    <h5>B. Kişisel Verilerinizin İşlenme Amaçları ve Hukuki Sebepleri</h5>
                    <p>
                        <a href="https://www.mevzuat.gov.tr/MevzuatMetin/1.5.6698.pdf" target="_blank">Kişisel Verilerin Korunması Kanunu</a>’nun (“Kanun”) 5. ve 6. maddeleri, kişisel veri işlemenin hukuki sebeplerini düzenlemektedir. Bu Aydınlatma Metninde belirtilen kişisel verileriniz, T. Vakıfbank Bankası T.A.O (“Vakıfbank”) Vinov başvurunuz nedeniyle Vakıfbank tarafından Üniversitemizden talep edilmektedir.
                        Kişisel verileriniz bu kapsamda açık rızanızı vermiş olmanız halinde, ilgili kişinin açık rızasının bulunması hukuki sebebine dayalı olarak, Vinov başvurunda bulunan kişiler tarafından gerekli kriterlerin karşılanıp karşılanmadığının değerlendirilmesi ve neticelendirilmesi nedeniyle Üniversitemize yöneltilen bilgi taleplerinin karşılanması kapsamında yetkili kişi, kurum ve kuruluşlara bilgi verilmesi amacıyla işlenmektedir.
                    </p>
                    <h5>C.İşlenen Kişisel Veriler</h5>
                    <p>Bu aydınlatma metninde belirtilen amaçlar dahilinde; kimlik (TCKN ve ad-soyad) ve cep telefon numarası kişisel verileriniz işlenmektedir.</p>
                    <h5>D.Kişisel Verilerin Paylaşıldığı Üçüncü Kişiler ve Paylaşım Amaçları</h5>
                    <p>
                        Kişisel verileriniz açık rızanızı vermiş olmanız halinde, bu aydınlatma metninin “B.Kişisel Verileri İşleme Amaçları ve Hukuki Sebepleri” başlığında belirtilen amaçlarla Vakıfbank’a aktarılacaktır. Kişisel verilerinizin üçüncü taraflara aktarımında Kanun’un 8. maddesinde belirtilen kurallara uygunluk sağlanmaktadır.
                    </p>
                    <h5>E. Kişisel Verilerinizi Toplanma Yöntemleri ve Hukuki Sebepleri</h5>
                    <p>
                        Kişisel verileriniz, yukarıda belirtilen amaçlar kapsamında fiziki ya da elektronik ortamda bilgi/belge sağlanması, telefon ve e-posta şeklindeki iletişim kanalları aracılığıyla kısmen otomatik ve bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yöntemler ile toplanmaktadır. Kişisel verileriniz, açık rızanızı vermiş olmanız halinde, ilgili kişinin açık rızasının bulunması hukuki sebebine dayalı olarak işlenmektedir.
                    </p>
                    <h5>F. Kişisel Verilerinize İlişkin Haklarınız</h5>
                    <p>
                        Kişisel Verilerin Korunması Kanunu’nun 11. maddesi “ilgili kişi haklarını” düzenlemektedir. Bu madde kapsamındaki kullanmak için, sistemlerimizde kayıtlı bulunan e-posta adresinizi kullanarak, kvkk@bau.edu.tr e-posta adresine gönderebilir veya posta yoluyla, Çırağan Cad. Osmanpaşa Mektebi Sok. no.4-6 Beşiktaş/İstanbul adresine iletebilirsiniz.
                        Haklarınızı kullanmak için ayrıca <a href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=24455&MevzuatTur=9&MevzuatTertip=5" target="_blank">Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ</a>’de belirtilen yöntemleri de tercih edebilirsiniz.
                    </p>
                </div>
                <div v-if="commonModalMode == 'vinovAcikRiza'">
                    <p>
                        Başvurmakta olduğunuz Vakıfbank Vinov süreci nedeniyle T. Vakıfbank Bankası T.A.O (“Vakıfbank”) tarafından Üniversitemizden kişisel veriler talep edilmektedir. Bu kapsamda, Bahçeşehir Üniversitesi olarak, kimlik (TCKN ve ad-soyad) ve cep telefon numarası verileriniz Vinov başvurunda bulunan kişiler tarafından gerekli kriterlerin karşılanıp karşılanmadığının değerlendirilmesi ve neticelendirilmesi için Üniversitemize yöneltilen bilgi taleplerinin karşılanması kapsamında yetkili kişi, kurum ve kuruluşlara bilgi verilmesi amaçlarıyla, Vakıfbank ile paylaşabilmek için açık rızanızı soruyoruz. Söz konusu kişisel verilerinizin bu kapsamda Vakıfbank ile paylaşılmasını onaylıyorsanız, ekranda yer alan onay kutucuğunu işaretleyebilirsiniz.
                    </p>
                    <p>
                        Bu Açık Rıza Metni’ni imzalamış olmanız, kimlik (TCKN ve ad-soyad) ve cep telefon numarası  verilerinizin bu açık rıza metninde belirtilen amaçlarla Vakıfbank’a aktarılacağını anladığınız ve açık rıza gösterdiğiniz anlamına gelecektir.
                    </p>
                </div>
            </template>
        </CommonModal>
        <CommonModal id="vinovForm" size="lg">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('new_vinov_instruction').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <ValidationObserver ref="vinovForm" v-if="vinovForm.amount">
                    <div class="row">
                        <div class="col-12">
                            <div class="label-as-input-div">
                                <label>{{ $t('amount') }}</label>
                                <div>{{ vinovForm.amount | formatToCurrency(vinovForm.currency) }}</div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="label-as-input-div">
                                <label>{{ $t('number_of_installments') }}</label>
                                <div>{{ vinovForm.number_of_installments }}</div>
                            </div>
                        </div>

                        <div class="col-12">
                            <ValidationProvider name="guardian_national_id" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('guardian_national_id')">
                                    <b-form-input
                                        v-model="vinovForm.guardian_national_id"
                                        v-mask="'###########'"
                                    ></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-12">
                            <ValidationProvider name="guardian_mobile_tel" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('guardian_mobile_tel')">
                                    <b-form-input
                                        v-model="vinovForm.guardian_mobile_tel"
                                        v-mask="'(5##) ### ## ##'"
                                    ></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-12">
                            <b-button type="submit" @click="saveVinov" variant="primary">{{
                                    $t('save')
                                }}
                            </b-button>
                        </div>

                    </div>
                </ValidationObserver>
            </template>
        </CommonModal>
        <epayment-footer></epayment-footer>
    </div>
</template>

<script>
import EPaymentService from "@/services/EPaymentService"
import EpaymentFooter from "./components/Footer"
import CommonModal from "@/components/elements/CommonModal";
import UserButton from "./components/UserButton"
import {ValidationProvider, ValidationObserver} from "vee-validate"
import VinovInstructionService from "@/services/VinovInstructionService";

export default {
    components: {
        EpaymentFooter,
        CommonModal,
        UserButton,
        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.$t('epayment')
        }
    },
    data() {
        return {
            tabIndex: 0,
            epaymentToken: null,
            epaymentUser: {
                number:null,
                name:null,
                surname:null,
            },
            debtsLoading:false,
            debts: {
                payment_plans: {},
                payments: {},
                virtual_pos: {},
            },
            advancePaymentMethod: null,
            oneShotPaymentMethod: null,

            // PAYMENT
            paymentDataType: null,
            paymentDebtId: null,
            paymentType: null,
            paymentDebt: {},

            paymentFormMonthOptions: [],
            paymentFormYearOptions: [],
            paymentFormInstallmentOptions: [],

            paymentFormAmount: 0,
            paymentFormShowAmount: 0,
            paymentFormRemainingAmount: 0,
            paymentFormSumAmount: 0,
            paymentFormMaxAmount: 0,
            paymentFormPrice: 0,

            paymentFormCardHolderName: null,
            paymentFormCreditCardNumber: '',
            paymentFormCreditCardLogo: null,
            paymentFormExpiryMonth: null,
            paymentFormExpiryYear: null,
            paymentFormCvv: null,
            paymentFormBankCode: null,
            paymentFormInstallment: null,
            paymentFormExtraInstallment: null,
            paymentFormInstallmentDisabled: true,
            paymentFormCheck: '',

            paymentResult: '',
            paymentOrderId: null,
            paymentStatus: null,

            commonModalModeSize: 'lg',
            commonModalMode: '',

            bankCreditAdvanceAmount:null,
            bankCreditInstallment:null,
            bankCreditInterestRate:20,

            vinovForm:{
                amount:0,
                kvkk:false,
                kvkk_read:false,
                acik_riza:false,
                acik_riza_read:false
            },
            vinovInstallmentFields:  [
                {key: 'name', label: this.$t('installment').toUpper(), thClass: 'text-center', tdClass: 'text-center'},
                {key: 'due_date', label: this.$t('due_date').toUpper(), thClass: 'text-center', tdClass: 'text-center'},
                {key: 'amount', label: this.$t('amount').toUpper(), thClass: 'text-center', tdClass: 'text-center'},
            ],
            hasVinov:'',
            selectedTab:null
        }
    },
    created() {
        this.$store.dispatch('epayment/initAuth')
            .then(() => {
                this.epaymentToken = this.$store.getters['epayment/getEpaymentToken'];
                this.epaymentUser  = this.$store.getters['epayment/getEpaymentUser'];
            })
            .then(() => {
                this.getDebts();
            });

        const year = new Date().getFullYear();
        for (var i = 1; i <= 12; i++) {
            this.paymentFormMonthOptions.push(i);
        }
        for (var i = 0; i <= 21; i++) {
            this.paymentFormYearOptions.push(year + i);
        }
    },
    methods: {
        clearDebt() {
            this.paymentDataType = null;
            this.paymentDebtId = null;
            this.paymentType = null;
            this.paymentDebt = {};
            this.paymentOrderId = null;
        },
        modifyProgramStatusFields(paymentType) {
            return [
                {key: 'payments_sum_amount', label: this.$t('amount_paid').toUpper(), thClass: 'text-center', tdClass: 'text-center'},
                {key: 'debt', label: this.$t('remaining_payment_amount').toUpper(), thClass: 'text-center', tdClass: 'text-center'},
                {key: 'total_price', label: this.$t('total_amount').toUpper(), thClass: 'text-center', tdClass: 'text-center'}
            ]
        },
        modifyProgramStatusItems(paymentType, item) {
            if(paymentType=='vinov'){
                return [
                    {
                        total_price: item.vinov_amount,
                        payments_sum_amount: item.payments_sum_amount,
                        debt: item.vinov_amount
                    }
                ]
            }
            return [
                {
                    total_price: (paymentType == 'advance' ? item.cash_price: item.price),
                    payments_sum_amount: item.payments_sum_amount,
                    debt: (paymentType == 'advance' ? item.cash_debt : item.debt)
                }
            ]
        },
        modifyPosFields(paymentType) {
            const cols = [];
            cols.push({key: 'card_type', label: this.$t('card_type').toUpper(), class: 'text-center align-middle'});
            if (paymentType != 'advance') {
                cols.push({key: 'installments', label: this.$t('installment_count').toUpper(), class: 'text-center align-middle'});
            }
            return cols;
        },
        modifyPosItems(paymentType, cards, currency) {
            const rows = [];
            if (cards && cards.length) {
                cards.forEach(function (card) {
                    if (card.currencies.includes(currency)) {
                        if (paymentType == 'advance') {
                            rows.push({
                                card_type: card.code,
                                pay: card.code
                            })
                        }
                        else {
                            rows.push({
                                card_type: card.code,
                                installments: card.installments,
                                extra_installment: card.extra_installment,
                                pay: card.code
                            })
                        }
                    }
                });
            }
            return rows;
        },
        getDebts() {
            this.debtsLoading = true;
            return EPaymentService.debts(this.epaymentToken)
                .then(response => {
                    this.debts = response.data.data;
                })
                .then(() => {
                    if (this.debts.virtual_pos) {
                        this.debts.virtual_pos.forEach((elem) => {
                            if (elem.is_default_for_one_shot) this.oneShotPaymentMethod = elem;
                        })
                    }
                    this.advancePaymentMethod = this.debts.default_virtual_pos;
                })
                .catch(error => {
                    this.$toast.error(this.$t('api.' + error.data.message));
                })
                .finally(() =>{
                    this.debtsLoading = false;
                })
        },
        getDebt(uuid) {
            return EPaymentService.debt(this.epaymentToken, uuid)
                .then(response => {
                    if (this.paymentDataType == 'payment_plan') {
                        this.paymentDebt = response.data.data.payment_plan;
                    }
                    else if (this.paymentDataType == 'payment') {
                        this.paymentDebt = response.data.data.payment;
                    }
                    else if (this.paymentDataType == 'external_payment') {
                        this.paymentDebt = response.data.data.external_payment;
                    }
                    else if (this.paymentDataType == 'certificate_plan') {
                        this.paymentDebt = response.data.data.certificate_plan;
                    }
                    else if (this.paymentDataType == 'certificate_payment') {
                        this.paymentDebt = response.data.data.certificate_payment;
                    }
                    else {
                        this.paymentDebt = response.data.data.other_payment;
                    }
                })
                .catch(error => {
                    this.$toast.error(this.$t('api.' + error.data.message));
                });
        },
        getPosImage(code) {
            if (code == 'vakifbank') {
                return require('@/assets/img/bank/world.svg');
            }
            else if (code == 'garanti') {
                return require('@/assets/img/bank/bonus.svg');
            }
            else if (code == 'ziraatbank') {
                return require('@/assets/img/bank/bankkart.svg');
            }
            else if (code == 'halkbank') {
                return require('@/assets/img/bank/paraf.svg');
            }
            else if (code == 'isbank') {
                return require('@/assets/img/bank/maximum.svg');
            }
            else if (code == 'akbank') {
                return require('@/assets/img/bank/axess.svg');
            }
        },
        paymentFormAmountCalculate() {
            this.paymentFormShowAmount = this.paymentFormAmount
            this.paymentFormRemainingAmount = this.paymentFormMaxAmount - this.paymentFormAmount;
        },
        paymentSetVars(dataType, uuid, paymentType) {
            this.paymentDataType = dataType;
            this.paymentDebtId = uuid;
            this.paymentType = paymentType;

            this.$refs.payForm.reset();
            this.loadDept();
        },
        loadDept(){
            this.paymentDebt = {};
            this.getDebt(this.paymentDebtId)
                .then(response => {
                    this.paymentFormAmount = 0;
                    this.paymentFormSumAmount = 0;
                    this.paymentFormMaxAmount = 0;

                    this.paymentFormCardHolderName = null;
                    this.paymentFormCreditCardNumber = '';
                    this.paymentFormExpiryMonth = null;
                    this.paymentFormExpiryYear = null;
                    this.paymentFormCvv = null;
                    this.paymentFormBankCode = null;
                    this.paymentFormInstallment = null;
                    this.paymentFormInstallmentDisabled = true;
                    this.paymentFormCheck = '';
                    this.paymentDebt.allow_partial=true;

                    if (this.paymentDataType == 'external_payment') {
                        this.paymentFormInstallmentOptions = [];
                        this.paymentFormPrice = this.paymentDebt.price
                        this.paymentFormAmount = this.paymentDebt.price
                        this.paymentFormSumAmount = 0;
                        this.paymentFormMaxAmount = this.paymentDebt.price
                        this.tabIndex = 1;
                        this.paymentDebt.allow_partial=false;
                    }
                    else if (this.paymentDataType == 'certificate_plan') {
                        this.paymentFormInstallmentOptions = [];
                        this.paymentFormPrice = this.paymentType == 'advance' ? this.paymentDebt.cash_price : this.paymentDebt.price
                        this.paymentFormAmount = this.paymentType == 'advance' ? this.paymentDebt.cash_debt : this.paymentDebt.debt
                        this.paymentFormSumAmount = this.paymentDebt.payments_sum_amount;
                        this.paymentFormMaxAmount = this.paymentFormAmount
                        this.tabIndex = 1;
                        this.paymentDebt.allow_partial=true;
                    }
                    else if (this.paymentType == 'advance') {
                        this.paymentFormInstallmentOptions = [1];
                        this.paymentFormInstallment = 1;
                        this.paymentFormAmount = this.paymentDataType == 'payment_plan' ? this.paymentDebt.cash_debt:this.paymentDebt.amount;
                        this.paymentFormPrice = this.paymentDataType == 'payment_plan' ? this.paymentDebt.cash_price:this.paymentDebt.amount;
                        this.paymentFormSumAmount = this.paymentDebt.payments_sum_amount ? this.paymentDebt.payments_sum_amount:0;
                        this.paymentFormMaxAmount = this.paymentFormAmount
                        this.tabIndex = 1;
                    }
                    else if (this.paymentType == 'installment') {
                        this.paymentFormInstallmentOptions = [];
                        this.paymentFormPrice = this.paymentDebt.price
                        this.paymentFormAmount = this.paymentDebt.debt
                        this.paymentFormSumAmount = this.paymentDebt.payments_sum_amount ? this.paymentDebt.payments_sum_amount:0;
                        this.paymentFormMaxAmount = this.paymentDebt.debt
                        this.tabIndex = 1;
                    }
                    if(['payment','other_payment','certificate_payment'].includes(this.paymentDataType) && this.paymentDebt.is_paid){
                        this.paymentFormAmount=0;
                        this.paymentFormMaxAmount=0;
                        this.paymentFormSumAmount=this.paymentDebt.amount;
                    }
                    if(parseFloat(this.paymentFormAmount)===0 && parseFloat(this.paymentFormSumAmount)>0){
                        setTimeout(()=>{
                            this.clearDebt();
                            this.getDebts();
                        }, 10000);
                    }
                })
                .finally(()=> {
                    this.paymentFormAmountCalculate();
                })
        },
        paymentFormInstallmentCustomLabel(value) {
            if (value == 1) {
                return this.$t('advance_payment');
            } else {
                return value +(this.paymentFormExtraInstallment>0?'+'+this.paymentFormExtraInstallment:'')+' ' + this.$t('installment');
            }
        },
        async pay() {
            const isValid = await this.$refs.payForm.validate();
            if (isValid) {
                const creditCardNumber = this.paymentFormCreditCardNumber;
                const formData = {
                    bank_code: this.paymentFormBankCode,
                    amount: this.paymentFormAmount,
                    card_holder_name: this.paymentFormCardHolderName,
                    credit_card_number: creditCardNumber.replace(/[^0-9]/g, ""),
                    expiry_month: this.paymentFormExpiryMonth,
                    expiry_year: this.paymentFormExpiryYear,
                    installment: this.paymentFormInstallment,
                    cvv: this.paymentFormCvv,
                    is_cash: this.paymentType == 'advance' ? 1 : 0,
                };
                return EPaymentService.pay(this.epaymentToken, this.paymentDebtId, formData)
                    .then(response => {
                        const data = response.data.data;

                        if(data.html){
                            this.paymentResult = data.html;
                            this.paymentOrderId = data.order_id;
                        }
                        else if(data.is_successful===false){
                            this.$toast.error(data.message);
                            return;
                        }
                        else if(data.is_successful===true) {
                            this.$refs.payForm.reset();
                            this.closedThreeDPayModal();
                            return;
                        }

                        this.$refs.threeDPayModal.$refs.commonModal.show()
                    })
                    .catch(error => {
                        if (error.status == 422) {
                            if (error.data.errors.amount) {
                                this.$refs.payForm.errors.payment_total.push(error.data.errors.amount);
                            }
                            if (error.data.errors.card_holder_name) {
                                this.$refs.payForm.errors.card_owner.push(error.data.errors.card_holder_name);
                            }
                            if (error.data.errors.credit_card_number) {
                                this.$refs.payForm.errors.card_number.push(error.data.errors.credit_card_number);
                            }
                            if (error.data.errors.expiry_month) {
                                this.$refs.payForm.errors.expiry_month.push(error.data.errors.expiry_month);
                            }
                            if (error.data.errors.expiry_year) {
                                this.$refs.payForm.errors.expiry_year.push(error.data.errors.expiry_year);
                            }
                            if (error.data.errors.installment) {
                                this.$refs.payForm.errors.installment.push(error.data.errors.installment);
                            }
                            if (error.data.errors.cvv) {
                                this.$refs.payForm.errors.cvc.push(error.data.errors.cvv);
                            }
                        }
                        else if (error.status == 406) {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        }
                    });
            }
        },
        showModal(mode) {
            this.commonModalMode = mode;
            this.$refs.paymentModal.$refs.commonModal.show()
        },
        closedThreeDPayModal() {
            if(this.paymentOrderId){
                return EPaymentService.paymentStatus(this.epaymentToken, this.paymentOrderId)
                    .then(response => {
                        this.paymentStatus = response.data.data.status;
                        this.paymentStatusMessage = response.data.data.message;
                        if(this.paymentStatus=='approved'){
                            this.$refs.payForm.reset();
                            this.loadDept();
                            this.$toast.success(this.$t('your_payment_transaction_has_been_successfully_completed'));
                            if(response.data.data.redirect_url){
                                setTimeout(()=>{
                                    window.location.href=response.data.data.redirect_url;
                                }, 2000);
                            }
                        }
                        else if(this.paymentStatus=='declined'){
                            this.$toast.error(this.$t('something_went_wrong')+' '+this.$t('please_try_again'));
                        }
                    })
                    .catch(error => {
                        this.paymentStatus = 'error';
                    });
            }
        },
        logout() {
            this.$router.push('epayment/logout')
        },
        calculateBankCredit(plan){
            if(!this.bankCreditInstallment){
                return;
            }
            let advanceAmount = parseInt(this.bankCreditAdvanceAmount);
            if(advanceAmount<0){
                advanceAmount=0;
            }
            let price = Math.round((plan.price-advanceAmount) * (100+this.bankCreditInterestRate) / 100 * 100)/100;
            let installmentAmount = Math.round(price / parseInt(this.bankCreditInstallment) * 100) / 100;

            let msg = 'Bugünkü oranlarla simülasyon sonucu, ';
            if(advanceAmount>0){
                msg+=this.formatNumber(advanceAmount)+' TL peşinat + '
            }
            msg+=this.formatNumber(price)+ '('+ this.formatNumber(installmentAmount)+'x'+this.bankCreditInstallment+ ') TL`dir.';
            this.$swal.fire({
                icon: null,
                text: msg,
                confirmButtonText: this.$t('ok'),
            })
        },
        downloadReceipt(id){
            EPaymentService.downloadReceipt(this.epaymentToken, id)
                .then(response=>{
                    this._downloadFile(response, id+'.pdf');
                })
                .catch(e=>{
                    this.showErrors(e)
                });
        },
        showVinovForm(paymentPlan){
            this.vinovForm.amount = paymentPlan.vinov_amount;
            this.vinovForm.currency = paymentPlan.currency;
            this.vinovForm.payment_plan_id=paymentPlan.id;
            this.vinovForm.number_of_installments=this.debts.vinov_number_of_installments;
            this.$bvModal.show('vinovForm');
        },
        showCertificateVinovForm(paymentPlan){
            this.vinovForm.amount = paymentPlan.price;
            this.vinovForm.currency = paymentPlan.currency;
            this.vinovForm.certificate_application_id=paymentPlan.id;
            this.vinovForm.number_of_installments=this.debts.vinov_number_of_installments;
            this.vinovForm.type='certificate';
            this.$bvModal.show('vinovForm');
        },
        async saveVinov(){
            const isValid = await this.$refs.vinovForm.validate();
            if(isValid){
                let formData = {...this.vinovForm};
                if(this.vinovForm.type && this.vinovForm.type=='certificate'){
                    VinovInstructionService.storeCertificateFromEpayment(this.epaymentToken, formData)
                        .then(response=>{
                            this.$bvModal.hide('vinovForm');
                            this.loadData();
                            let msg='Vinov başvurunuz başarılı şekilde oluşturulmuştur. Vinov başvurunuzu aşağıdaki adımları takip ederek onaylayabilirsiniz:<br>' +
                                '<a href="https://www.vakifbank.com.tr" target="_blank">www.vakifbank.com.tr</a> adresi üzerinden giriş yaparak Ödelemelerim -> Vinov İşlemleri -> Vinov Onay İşlemleri adımlarını takip ederek ilgili ekranlar üzerinden onay verebilirsiniz.<br>' +
                                'Vakıfbank Mobil uygulama üzerinden giriş yaparak Profil ->Sözleşme ve Belgeler -> Sözleşme ve Formlar -> Vinov Onay İşlemleri adımlarını takip ederek ilgili ekranlar üzerinden onay verebilirsiniz.';
                            this.$swal.fire({
                                icon: null,
                                html: msg,
                                confirmButtonText: this.$t('ok'),
                            })
                        }).catch(e=>{
                        this.showErrors(e, this.$refs.vinovForm);
                    });
                    return;
                }
                VinovInstructionService.storeStudentFromEpayment(this.epaymentToken, formData)
                    .then(response=>{
                        this.$bvModal.hide('vinovForm');
                        this.loadData();
                        let msg='Vinov başvurunuz başarılı şekilde oluşturulmuştur. Vinov başvurunuzu aşağıdaki adımları takip ederek onaylayabilirsiniz:<br>' +
                            '<a href="https://www.vakifbank.com.tr" target="_blank">www.vakifbank.com.tr</a> adresi üzerinden giriş yaparak Ödelemelerim -> Vinov İşlemleri -> Vinov Onay İşlemleri adımlarını takip ederek ilgili ekranlar üzerinden onay verebilirsiniz.<br>' +
                            'Vakıfbank Mobil uygulama üzerinden giriş yaparak Profil ->Sözleşme ve Belgeler -> Sözleşme ve Formlar -> Vinov Onay İşlemleri adımlarını takip ederek ilgili ekranlar üzerinden onay verebilirsiniz.';
                        this.$swal.fire({
                            icon: null,
                            html: msg,
                            confirmButtonText: this.$t('ok'),
                        })
                    }).catch(e=>{
                    this.showErrors(e, this.$refs.vinovForm);
                });
            }
        },
        tabSelected(tab){
            this.selectedTab=tab;
        }
    },
    watch: {
        paymentFormCreditCardNumber(value, oldValue) {
            if (value!='' && value.length >= 8) {
                let value8Char = value.replaceAll(' ','').substring(0, 8);
                let oldValue8Char = oldValue.replaceAll(' ','').substring(0, 8);
                if(value8Char != oldValue8Char) {
                    this.paymentFormCreditCardLogo = null;
                    if(value8Char.length!=8){
                        return;
                    }
                    return EPaymentService.virtualPosBin(this.epaymentToken, value8Char)
                        .then(response => {
                            var bank = response.data.data;

                            this.paymentFormBankCode = null;
                            this.paymentFormInstallmentOptions = null;
                            this.paymentFormExtraInstallment = null;

                            // Pos Check
                            this.debts.virtual_pos.forEach((elem) => {
                                if(elem.code==bank){
                                    this.paymentFormBankCode = elem.code;

                                    if(this.paymentType=='installment'){
                                        let installment = elem.installments;
                                        if(installment && installment.length==1){
                                            this.paymentFormInstallment = installment[0];
                                            if(elem.extra_installment>0){
                                                this.paymentFormExtraInstallment=elem.extra_installment;
                                            }
                                        }
                                    }
                                    else{
                                        let installment = [1];
                                        this.paymentFormInstallment = installment[0];
                                    }
                                    this.paymentFormInstallmentOptions = this.paymentType=='installment' ? elem.installments:[1];
                                }
                            })

                            // Pos var/yok ise?
                            if (this.paymentFormBankCode) {
                                this.paymentFormCreditCardLogo = this.paymentFormBankCode;
                                if (this.paymentType == 'advance') {
                                    if(this.oneShotPaymentMethod){
                                        this.paymentFormBankCode = this.oneShotPaymentMethod.code;
                                    }
                                    this.paymentFormInstallment = 1;
                                }

                                this.paymentFormInstallmentDisabled = false;
                            }
                            else {
                                if (this.paymentType == 'advance') {
                                    if(this.advancePaymentMethod){
                                        this.paymentFormBankCode = this.advancePaymentMethod.code;
                                        this.paymentFormInstallment = 1;
                                        this.paymentFormInstallmentDisabled = false;
                                    }
                                    else {
                                        this.paymentFormInstallmentDisabled = true;
                                    }
                                }
                                else if (this.paymentType == 'installment') {
                                    this.$toast.error(this.$t('no_installment_message'));
                                    this.paymentFormInstallmentDisabled = true;
                                }
                            }
                        })
                        .catch(error => {
                            this.paymentFormInstallmentDisabled = true;
                            if (error.status == 406) {
                                if (error.data.message == 'BIN_NOT_FOUND') {
                                    if (this.paymentType == 'advance' && this.advancePaymentMethod) {
                                        this.paymentFormBankCode = this.advancePaymentMethod.code;
                                        this.paymentFormInstallment = 1;
                                        this.paymentFormInstallmentDisabled = false;
                                    }
                                    else if (this.paymentType == 'installment') {
                                        this.$toast.error(this.$t('no_installment_message'));
                                    }
                                }
                                else if (this.paymentType == 'installment') {
                                    this.$toast.error(this.$t('no_installment_message'));
                                }
                            }
                        })
                }
            }
            else {
                this.paymentFormBankCode = null;
                this.paymentFormInstallmentOptions = [];
                this.paymentFormInstallment = null;
                this.paymentFormInstallmentDisabled = true;
                this.paymentFormCreditCardLogo = null;
            }
        }
    }
}
</script>
